<template>
	<Alert v-if="showAlert" :closeLabel="$t('alert.cancel')" :submitLabel="$t('alert.confirm')" @close="showAlert = false" @submit="$router.push('/account')">
		<div class="pb-5 text-center text-xl">{{ $t('alert.warning') }}</div>
	</Alert>
	<BaseLayout>
		<Modal v-if="showAreaCodeModal" @close="showAreaCodeModal = false" :title="$t('location.locationSearch')">
			<template v-slot:search>
				<div class="absolute top-20 w-full">
					<Search>
						<input v-model="inputAreaCode" type="text" class="ml-2 w-full bg-transparent text-sm" :placeholder="$t('location.locationSearch')" />
					</Search>
				</div>
			</template>

			<div class="pt-16">
				<div class="flex h-full flex-col overflow-x-scroll bg-white">
					<div v-for="country in searchAreaCode" :key="country" class="flex justify-between py-2" :class="country.dial_code == customer.billingAddress.areaCode ? 'bg-primary text-white' : null">
						<label :for="country.dial_code" class="whitespace-nowrap px-5 py-1 text-lg">
							{{
								`${country.name}
						（${country.dial_code}）`
							}}</label
						>
						<input class="w-full appearance-none" v-model="selectAreaCode" :id="country.dial_code" :value="country.dial_code" :checked="country.dial_code == customer.billingAddress.areaCode" type="radio" :name="country" @change=";(customer.billingAddress.areaCode = selectAreaCode), (showAreaCodeModal = false)" />
					</div>
				</div>
			</div>
		</Modal>
		<div v-else>
			<Toolbar @clickLeft="isDisabled ? $router.push('/account') : (showAlert = true)" @clickRight="onToggle">
				<template v-slot:start>
					<Icon class="h-5 w-5" icon="angleLeft" />
				</template>
				<template v-slot:end>{{ isDisabled ? $t('profile.editProfile') : $t('profile.cancelProfile') }}</template>
			</Toolbar>
			<form @submit.stop.prevent="submit" class="flex flex-col space-y-3 px-5 pb-20 text-lg">
				<List :title="$t('profile.verifyAccount')">
					<list-item v-if="customer.phoneNumber" :title="$t('profile.phoneNumber')">
						<div class="flex justify-end">
							<p>{{ customer.areaCode }}</p>
							<p>{{ customer.phoneNumber }}</p>
						</div>
					</list-item>
					<list-item v-if="customer.emailAddress" :title="$t('profile.emailAddress')">
						<p>{{ customer.emailAddress }}</p>
					</list-item>
				</List>
				<!-- 會員個人資料 -->
				<List :title="$t('profile.personalInfo')">
					<list-item :title="$t('profile.name')">
						<input v-model="customer.firstName" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('profile.editName')" :disabled="isDisabled" />
					</list-item>
					<list-item :title="$t('profile.surname')">
						<input v-model="customer.lastName" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('profile.editSurname')" :disabled="isDisabled" />
					</list-item>
					<list-item :title="$t('profile.gender')">
						<select v-model="customer.gender" :class="isDisabled ? 'text-muted' : 'text-black'" class="col-span-5 appearance-none bg-transparent text-right placeholder-muted disabled:opacity-50" dir="rtl" :disabled="isDisabled">
							<option value="X" disabled>{{ $t('profile.selectGender') }}</option>
							<option value="M">{{ $t('profile.male') }}</option>
							<option value="F">{{ $t('profile.female') }}</option>
						</select>
					</list-item>
					<list-item :title="$t('profile.birthday')">
						<button v-if="isNative" class="flex w-full justify-end disabled:opacity-50" :disabled="isDisabled" :class="isDisabled ? 'text-muted' : 'text-black'" @click="onDatePicker">{{ customer.birthday ? customer.birthday : $t('profile.editBirthday') }}</button>
						<input v-else v-model="customer.birthday" type="date" :max="setMinDate(18)" class="w-full appearance-none bg-transparent text-right placeholder-muted" :disabled="isDisabled" />
					</list-item>
				</List>

				<List v-for="(billingAddress, index) in customer.billingAddresses" :key="index" :title="$t('profile.billingAddress')">
					<list-item :title="$t('profile.billingAddressFirstName')">
						<input v-model="billingAddress.firstName" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('profile.editBillingAddressFirstName')" :disabled="isDisabled" />
					</list-item>
					<list-item :title="$t('profile.billingAddressLastName')">
						<input v-model="billingAddress.lastName" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('profile.editBillingAddressLastName')" :disabled="isDisabled" />
					</list-item>
					<list-item :title="$t('profile.billingAddressAreaCode')">
						<div @click="isDisabled ? null : (showAreaCodeModal = true)" class="inline-flex items-center space-x-1" :disabled="isDisabled">
							<p class="text-md whitespace-nowrap" :class="isDisabled ? 'text-muted' : null">{{ billingAddress.areaCode ? billingAddress.areaCode : $t('signin.countryCode') + $t('signin.countryCode2') }}</p>

							<!-- <Icon class="w-4 h-4" icon="angleDown" /> -->
							<Icon v-if="!isDisabled" class="h-3 w-3" icon="angleDown" />
						</div>
						<!-- <input v-model="customer.billingAddress.areaCode" class="w-full text-right bg-transparent placeholder-muted" :placeholder="$t('profile.editBillingAddressAreaCode')" :disabled="isDisabled" /> -->
					</list-item>
					<list-item :title="$t('profile.billingAddressPhoneNumber')">
						<input v-model="billingAddress.phoneNumber" type="tel" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('profile.editBillingAddressPhoneNumber')" :disabled="isDisabled" />
					</list-item>
					<list-item :title="$t('profile.billingAddressAddressLine')">
						<input v-model="billingAddress.addressLine" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('profile.editBillingAddressAddressLine')" :disabled="isDisabled" />
					</list-item>
				</List>

				<Footer class="-mx-5" v-if="!showAreaCodeModal">
					<Button v-if="!isDisabled" type="submit" :loading="isLoading" :disabled="isLoading">{{ $t('profile.submitChange') }}</Button>
				</Footer>
			</form>
		</div>
	</BaseLayout>

	<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Capacitor } from '@capacitor/core'
import { DatePicker } from '@pantrist/capacitor-date-picker'
import { useCustomerStore } from '@/store/customer'
import { useI18n } from 'vue-i18n'
import { customerApi } from 'gox-sdk'
import moment from 'moment'

export default {
	setup() {
		const store = useStore()
		const router = useRouter()
		const { locale } = useI18n()
		const { currentCustomer } = storeToRefs(useCustomerStore())
		const customer = currentCustomer.value
		const countryLists = store.state.config.countryCode

		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)
		const isDisabled = ref(true)
		const isNative = Capacitor.isNativePlatform()
		const inputAreaCode = ref('')
		const showAreaCodeModal = ref(false)
		const showAlert = ref(false)

		function onToggle() {
			showAreaCodeModal.value = false
			if (isDisabled.value) return (isDisabled.value = false)
			isDisabled.value = true
			router.go(-1)
		}

		function onDatePicker() {
			DatePicker.present({
				mode: 'date',
				date: customer.birthday ? customer.birthday : null,
				format: 'yyyy-MM-dd',
				max: setMinDate(18),
			}).then(date => {
				isDisabled.value = false
				customer.birthday = date.value
			})
		}

		function setMinDate(value) {
			return moment().subtract(value, 'year').format('YYYY-MM-DD')
		}

		async function submit() {
			if (isDisabled.value) return
			store.commit('setLoadingState', { loadingState: true })
			error.value = null
			try {
				await customerApi.updateCustomer(customer.id, customer)
				// await store.dispatch('updateCustomer', { customer: customer.value })
				store.commit('setLoadingState', { loadingState: false })
				isDisabled.value = true
			} catch (e) {
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				isDisabled.value = true
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}
		const searchAreaCode = computed(() => {
			return countryLists[locale.value].filter(item => {
				const fields = ['name', 'code', 'dial_code']
				let flag = false
				fields.forEach(field => {
					if (item[field].toUpperCase().includes(inputAreaCode.value.toUpperCase())) flag = true
				})
				if (flag) return item
			})
		})

		return {
			customer,
			submit,
			isDisabled,
			isLoading,
			isNative,
			onToggle,
			onDatePicker,
			error,
			showAreaCodeModal,
			countryLists,
			searchAreaCode,
			inputAreaCode,
			showAlert,
			setMinDate,
		}
	},
}
</script>
